export const getApiDomain = (): string => {
  const location = window.location.href;

  if (location.includes('stg.fraud-dao.com')) {
    return 'https://api.stg.fraud-dao.com';
  } else if (location.includes('dashboard.fraud-dao.com')) {
    return 'https://api.prod1.fraud-dao.com';
  }

  return 'http://localhost:8001';
};
